<template>
    <section class="d-flex align-content-start flex-wrap custom-scroll overflow-auto" style="height: calc(100vh - 192px); gap: 1rem;">
        <div v-for="(data, index) in carpetasList" :key="index" class="d-middle justify-content-between card-ventana cr-pointer pr-3 pl-2" @click="goDetail(data.id)">
            <div class="d-middle">
                <img src="/img/generales/CarpetaColor.svg" style="width:50px;height: 42px;" />
                <div class="ml-2" style="max-width: 200px;">
                    <p class="f-16 tres-puntos ml-1 font-weight-normal">{{ data.nombre }}</p>
                    <i :class="`${iconTipo(data.tipo)}`" /> <span class="f-16">{{ textTipo(data.tipo) }}</span>
                </div>
            </div>
            <el-tooltip placement="bottom-start" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                <div slot="content" class="tooltip-editar-residente">
                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="actualizarNombre(data)">
                        <p class="f-14">Cambiar nombre</p>
                    </div>
                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="actualizarPrivacidad(data)">
                        <p class="f-14">Privacidad</p>
                    </div>
                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="verDetalle(index)">
                        <p class="f-14">Detalle</p>
                    </div>
                    <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="eliminarCarpeta(data.id)">
                        <p class="f-14">Eliminar</p>
                    </div>
                </div>
                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                    <i class="icon-vertical-points text-black" />
                </div>
            </el-tooltip>
        </div>
    </section>
</template>


<script>
export default {
    props:{
        carpetas: Array,
	  search: String
    },
    data(){
        return{
            
        }
    },
    computed: {
        carpetasList(){
            return this.carpetas.filter(value => !this.search || String(value.nombre).toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    methods:{    
        iconTipo(tipo){
            switch (tipo){
            case 1:
                return 'icon-lock'  
            case 2:
                return 'icon-group-outline'
            case 3:
                return 'icon-account-plus'    
            }
        },
        textTipo(tipo){
            switch (tipo){
            case 1:
                return 'Privada' 
            case 2:
                return 'Pública'
            case 3:
                return 'Compartida'
            }
        },
        eliminarCarpeta(item){
            this.$emit('eliminar',item)
        },
        actualizarNombre(item){
            this.$emit('actualizar',item)
        },
        actualizarPrivacidad(item){
            this.$emit('privacidad',item)
        },
        verDetalle(item){
            this.$emit('detalle',item)
        },
        goDetail(item){
            this.$router.push({ name:'biblioteca.carpetas.archivos', params: { idCarpeta: item }})
        }
    }
}
</script>

<style lang="scss" scoped>

.card-ventana {
	width: 327px;
	height: 74px;
	border-radius: 8px;
	border: 1px solid #DBDBDB;
	color: #5F6D84;
}
</style>